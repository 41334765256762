<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Creative Profile</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10">
        <v-sheet outlined class="pa-5">
          <p
            v-if="member.creative_description"
            v-html="member.creative_description"
          ></p>
          <p v-else class="text--disabled">Creative Description Incomplete</p>
          <h3>I am Statement</h3>
          <p v-if="member.i_am_statement" v-html="member.i_am_statement"></p>
          <p v-else class="text--disabled">I Am Statement Incomplete</p>
          <h3>Why I am Here</h3>
          <p v-if="member.why_i_am_here" v-html="member.why_i_am_here"></p>
          <p v-else class="text--disabled">Why I Am Here Incomplete</p>
        </v-sheet>
      </v-col>
      <v-col cols="2">
        <v-avatar tile color="accent" size="140" width="100%">
          <v-img
            v-if="member.profile_picture"
            :src="member.asset_urls.profile_picture"
          ></v-img>
        </v-avatar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
};
</script>
